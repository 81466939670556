import React, { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { datatableStyle } from "./datatableStyle";

const DataTable = ({ columnsfrom, data }) => {
  const columns = useMemo(() => columnsfrom, [columnsfrom]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableTopToolbar: false,
    enablePagination: false, // Disable pagination
    muiTableProps: {
      stickyHeader: true, // Enable sticky headers
    },
    ...datatableStyle,
  });

  return (
    <div className="rounded-lg bg-white shadow-lg overflow-hidden">
      <MaterialReactTable table={table} />
    </div>
  );
};

export default DataTable;
