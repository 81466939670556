import * as React from 'react';
import { SvgIcon } from '@mui/material';

const VerifiedIcon = (props) => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.1106 11.535C10.2981 11.625 10.5313 11.8582 10.6444 12.0463C10.6819 12.3088 10.8694 11.2944 11.7869 10.6932M13.4375 11.2507C13.4375 11.9137 13.1741 12.5496 12.7053 13.0184C12.2364 13.4873 11.6005 13.7507 10.9375 13.7507C10.2745 13.7507 9.63857 13.4873 9.16973 13.0184C8.70089 12.5496 8.4375 11.9137 8.4375 11.2507C8.4375 10.5876 8.70089 9.95175 9.16973 9.4829C9.63857 9.01406 10.2745 8.75067 10.9375 8.75067C11.6005 8.75067 12.2364 9.01406 12.7053 9.4829C13.1741 9.95175 13.4375 10.5876 13.4375 11.2507Z" stroke="#12843F" stroke-width="0.9375" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.88 8.75067C7.57036 8.75067 8.13 8.19103 8.13 7.50067C8.13 6.81032 7.57036 6.25067 6.88 6.25067C6.18965 6.25067 5.63 6.81032 5.63 7.50067C5.63 8.19103 6.18965 8.75067 6.88 8.75067Z" stroke="#12843F" stroke-width="0.9375" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M4.69253 11.2507C5.28628 10.6882 5.78628 10.3757 6.89565 10.3132M12.1925 7.18815C12.1925 6.68815 12.2082 5.1569 12.13 4.5319C12.0832 4.01628 11.9425 3.31315 11.3019 2.9069C10.9113 2.70378 10.5363 2.51628 8.7394 2.50065M4.97378 2.50065C3.63003 2.50065 2.58315 2.61003 2.06753 3.25065C1.63003 3.84878 1.65565 4.5319 1.6144 4.75065C1.52065 5.92253 1.58315 10.0163 1.58315 10.7038C1.58315 11.4225 1.53628 12.8832 2.52065 13.3757C3.3644 13.7975 4.2394 13.735 7.20815 13.7507M6.78628 1.25065C6.41128 1.25065 6.09878 1.25065 5.78628 1.57878C5.52065 1.82878 5.56128 2.0494 5.45815 2.43815C5.3119 2.98503 5.2394 3.27378 5.44253 3.50065C5.62878 3.7444 5.9419 3.74565 6.21878 3.74628H6.22378C6.4894 3.76503 7.3819 3.75628 7.64565 3.74628C7.92815 3.73503 8.15628 3.7194 8.34878 3.4694C8.52065 3.24628 8.41878 2.8744 8.31753 2.48503C8.21753 2.10128 8.25503 1.9069 7.9894 1.57878C7.6144 1.20378 7.16128 1.25065 6.78628 1.25065Z" stroke="#12843F" stroke-width="0.9375" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)




export default VerifiedIcon