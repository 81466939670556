import React, { useState } from "react";
import { Header, Sidebar } from "../components";
import { Avatar } from "@mui/material";
import { IoIosSearch } from "react-icons/io";
import SortableTable from "../components/SortableTable";
import ReactVirtualizedTable from "../components/DataTable/DataTable";
import { useNavigate } from "react-router-dom";

import { IoStatsChartSharp } from "react-icons/io5";

const data = [
  { label: "Total Orders in Waiting for contractor to confirm", value: 177 },
  { label: "Total orders in Requestor Verfication pending", value: 221 },
  { label: " Ready to be paid", value: 11 },
  { label: "Total orders in Paid", value: 23 },
  { label: "Total orders in Unpaid", value: 336 },
];

export function Home() {
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="p-5 px-8 animate-fade flex gap-8">
      <div className="flex  flex-col w-full gap-3">
        <p className="text-4xl font-Poppins font-semibold">Home</p>
        {/* <SortableTable loading={loading} tableData={data} /> */}
        {/* <ReactVirtualizedTable data={data} columnsfrom={columns} /> */}
        <div className="bg-stone-100 border p-5 h-[480px] rounded-2xl">
          <div className="flex flex-wrap items-center mt-10 px-12 gap-12">
            {data.map((obj, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    navigate("/app/leads");
                  }}
                  className="w-80 p-5 border hover:scale-105 transition-all duration-200 active:scale-[0.99] h-36 hover:cursor-pointer bg-light-background-accent rounded-xl"
                >
                  <div className="flex gap-2 flex-col">
                    <div className="rounded-full p-3 w-fit bg-light-background-neww">
                      <IoStatsChartSharp color="white" className="w-8 h-8" />
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-sm text-dark-background-neww font-semibold">
                        {obj.label}
                      </p>
                      <p className="text-5xl text-dark-background-neww font-bold">
                        {obj.value}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div className="flex mt-10 px-10 gap-4">
            <div className="w-full h-56 bg-stone-200 rounded-xl"></div>
            <div className="w-full h-56 bg-stone-200 rounded-xl"></div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
