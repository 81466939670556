import * as React from 'react';
import { SvgIcon } from '@mui/material';

const EditIcon = (props) => (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="35" height="35" fill="#00B2D6" fill-opacity="0.2" />
        <path d="M20.304 9.84399L23.156 12.696M13 12H10C9.73478 12 9.48043 12.1054 9.29289 12.2929C9.10536 12.4804 9 12.7348 9 13V23C9 23.2652 9.10536 23.5196 9.29289 23.7071C9.48043 23.8946 9.73478 24 10 24H21C21.2652 24 21.5196 23.8946 21.7071 23.7071C21.8946 23.5196 22 23.2652 22 23V18.5M24.409 8.58999C24.5964 8.7773 24.745 8.99969 24.8464 9.24445C24.9478 9.48921 25 9.75156 25 10.0165C25 10.2814 24.9478 10.5438 24.8464 10.7885C24.745 11.0333 24.5964 11.2557 24.409 11.443L17.565 18.287L14 19L14.713 15.435L21.557 8.59099C21.7442 8.40353 21.9664 8.25481 22.2111 8.15334C22.4558 8.05186 22.7181 7.99963 22.983 7.99963C23.2479 7.99963 23.5102 8.05186 23.7549 8.15334C23.9996 8.25481 24.2218 8.40353 24.409 8.59099V8.58999Z" stroke="#00B2D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export default EditIcon;