import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { $crud } from "../../utils/CrudFactory";

function AddCategory() {
  const [category, setCategory] = useState("");
  const navigate = useNavigate();
  async function handleAdd() {
    try {
      await $crud.post("admin/category", { name: category });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  }
  return (
    <div className="p-5 px-8 animate-fade flex gap-8">
      <div className="flex flex-col w-full gap-3">
        <div className="flex font-Poppins gap-2 items-center">
          <p
            onClick={() => navigate(-1)}
            className="text-4xl cursor-pointer font-semibold"
          >
            &larr;
          </p>
          <p className="text-4xl font-semibold">Add Category</p>
        </div>
        <div className="bg-stone-100 flex flex-col justify-between font-Poppins border p-10 h-[480px] rounded-2xl">
          <div className="flex flex-col gap-2">
            <label className="text-xl font-semibold">Category Name</label>
            <input
              value={category}
              autoFocus
              onChange={(e) => setCategory(e.target.value)}
              type="text"
              className="w-72 rounded-md border outline-none px-4 focus:ring-1 ring-light-text-neww h-10"
            />
          </div>
          <div className="flex gap-3 justify-end items-center">
            <button
              onClick={() => {
                navigate("/app/category");
              }}
              className="bg-red-500 rounded-md text-white p-2 px-4"
            >
              Cancel
            </button>
            <button
              onClick={handleAdd}
              className="bg-light-background-neww rounded-md text-white p-2 px-4"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCategory;
