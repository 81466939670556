import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff, Email, Security, SecurityOutlined, WidthFull } from "@mui/icons-material";
import HttpsIcon from '@mui/icons-material/Https';

export const CustomTextField = ({
    name,
    placeholder,
    label,
    id,
    value,
    onChange,
    onBlur,
    error,
    helperText,
    type,
    ...rest
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((prev) => !prev);

    const commonTextFieldProps = {
        name,
        placeholder,
        label,
        id,
        value,
        onChange,
        onBlur,
        error,
        helperText,
        size: "small",
        variant: "outlined",
        sx: {
            mb: 3,
            '& .MuiOutlinedInput-root': {
                borderRadius: '100px', // or any value you want
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#00B2D6', // your color
            },

        },
        ...rest,
    };

    if (name === "password") {
        return (
            <TextField
                {...commonTextFieldProps}
                type={showPassword ? "text" : type}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <HttpsIcon />
                        </InputAdornment>
                    ),
                    endAdornment: ( // Add this for the end adornment
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    if (name === "email") {
        return (
            <TextField
                {...commonTextFieldProps}
                type={type}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Email />
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    if (name === "otp") {
        return (
            <TextField
                {...commonTextFieldProps}
                type="text" // Important: Set type to "number"
                inputProps={{
                    maxLength: 1, // Limit to 1 character
                    style: {
                        display: 'flex',
                        width: '48px',
                        height: '48px',
                        padding: '1px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center', // Center the text
                        
                    },
                }}
                sx={{
                    ...commonTextFieldProps.sx,
                    '& .MuiOutlinedInput-root': {
                        ...commonTextFieldProps.sx['& .MuiOutlinedInput-root'],
                        borderRadius: '100px',
                        border: '1px solid #00B2D6',
                        background: 'rgba(0, 178, 214, 0.10)',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    mb: 3,
                    
                    
                }}
                onChange={(event) => { 
                    onChange(event.target.value)

                }}
            />
        );
    }

    return (
        <TextField
            {...commonTextFieldProps}
            type={type}
            fullWidth
        />
    );
};