import * as React from 'react';
import { SvgIcon } from '@mui/material';

const LeftArrow = (props) => (
  <SvgIcon {...props}>
    <path d="M0.999904 7.99976H20.9999" stroke="white" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.24963 14.25C7.24963 14.25 0.999756 9.647 0.999756 8C0.999756 6.353 7.24976 1.75 7.24976 1.75" stroke="white" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default LeftArrow;