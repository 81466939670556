import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { CustomButton } from "../../components";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../hooks/useLogin";
import HandBImage from "../../assets/HandBImage.png"
import DoubleInvertedComma from "../../components/DoubleInvertedComma";
import LeftArrow from "../../components/LeftArrow";
import RightArrow from "../../components/RightArrow";
import EmailIcon from "../../assets/EmailIcon.png"
import { ImageContent } from "../../components/ImageContent ";
export function OtpConfirmPassword() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpValues, setOtpValues] = useState(["", "", "", ""]);
    const inputRefs = useRef([]);
    const { initialValues, isSubmitting, onSubmit, validationSchema } = useLogin();
    const { values, handleChange, handleBlur, errors, touched, handleSubmit } = useFormik({
        initialValues,
        onSubmit: (values) => {
            onSubmit({ ...values, otp }); 
        },
        validationSchema,
    });
    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);
    const handleOtpChange = (index, event) => {
        const inputValue = event.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        const newOtpValues = [...otpValues];
        newOtpValues[index] = numericValue;
        setOtpValues(newOtpValues);
        if (numericValue !== "") {
            if (inputRefs.current[index + 1]) {
                inputRefs.current[index + 1].focus();
            }
        } else if (event.key === 'Backspace') {
            if (inputRefs.current[index - 1]) {
                inputRefs.current[index - 1].focus();
            }
        }
        const completeOtp = newOtpValues.join("");
        setOtp(completeOtp);
    };
    return (
        <Grid container sx={{ height: "100vh" }}>
            <ImageContent description="Lorem ipsum dolor sit amet, conse elit, sed do eiusmod tempor i ut labore et dolore magna aliqua.Utenim ad minim veniam, grdrerlaboris nisi ut aliquip ex ea commodo consequat." />
            <Grid
                item
                lg={6}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    flexDirection: "column",
                    height: "100%",
                }}
            >
                <Box
                    sx={{
                        maxWidth: {
                            xs: "270px",
                            md: "300px",
                            lg: "512px",
                        },
                        padding: "16px", 
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', alignContent: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, justifyContent: 'center', flexDirection: 'row', alignContent: 'center', mb: 2 }}>
                            <Typography
                                variant="body2"
                                color="black"
                                sx={{
                                    backgroundColor: '#CCF0F7',
                                    padding: 2,
                                    borderRadius: 100
                                }}
                            >
                                <img src={EmailIcon} />
                            </Typography>
                        </Box>
                        <Typography
                            onClick={() =>
                                navigate("/forgot-password", {
                                    state: { changeAccPassword: false },
                                })
                            }
                            sx={{
                                cursor: "pointer",
                                fontWeight: 500,
                                textAlign: "right",
                                mb: 2,
                            }}
                            variant="body2"
                            color="#00B2D6"
                        >
                        </Typography>
                    </Box>
                    <Typography
                        variant="h4"
                        sx={{
                            color: "black",
                            fontWeight: "bold",
                            mb: 3,
                            textAlign: "left",
                        }}
                    >
                        OTP Verification
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            color: "#00B2D6",
                            mb: 3,
                            textAlign: "left",
                            marginTop: '-5%',
                            fontSize: 14,
                        }}
                    >
                        Check your email to see the verification code
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                            {["", "", "", ""].map((_, index) => (
                                <TextField
                                    key={index}
                                    type="text"
                                    value={otpValues[index]}
                                    onChange={(event) => handleOtpChange(index, event)}
                                    inputProps={{
                                        maxLength: 1, 
                                        style: {
                                            display: 'flex',
                                            width: '48px',
                                            height: '48px',
                                            padding: '1px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center', 
                                        },
                                    }}
                                    sx={{
                                        borderRadius: '100px',
                                        border: '1px solid #00B2D6',
                                        background: 'rgba(0, 178, 214, 0.10)',
                                        mb: 3,
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    }}
                                />
                            ))}
                        </Box>
                        <CustomButton type="submit" disabled={isSubmitting} BorderRadius={"100px"} onClick={() => navigate('/SetNewPassword')}  >
                            Verify & Login
                        </CustomButton>
                        <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 5 }}  >
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    flexDirection: 'row'
                                }}
                                variant="body2"
                                color="black"
                            >
                                Resend code in
                            </Typography>
                            <Typography sx={{
                                color: 'rgba(0, 178, 214, 1)', textAlign: "center",
                                fontSize: "14px",
                                fontStyle: "normal",
                                flexDirection: 'row', paddingLeft: 1
                            }}>   00:59</Typography>
                        </Box>
                    </form>
                </Box>
            </Grid>
        </Grid >
    );
}