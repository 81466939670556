import { Avatar, TextField, Typography } from "@mui/material";
import React from "react";
import Location from "../../components/Location";
import RegistrationIcon from "../../components/RegistrationIcon";
import { CustomTextField } from "../../components";
import EditIcon from "../../components/EditIcon"

function Profile() {
  return (
    <div className="p-5 animate-fade px-8 flex gap-8">
      <div className="flex  flex-col w-full gap-3">
        <p className="text-4xl font-Poppins font-semibold">Profile</p>
        <div className="h-[220px] border-b-2 border-[#00B2D6] rounded-b-lg ">
          <div className="bg-[#00B2D6] flex justify-center items-center ">
            <div className="flex items-center  p-2 justify-center flex-col mt-[50px] mb-[-95px]">
              <Avatar sx={{ width: "80px", height: "80px" }} />
              <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: 'black' }}>John Doe</Typography>
              <Typography sx={{ color: '#00B2D6', fontSize: 12 }}>mr.example@gmail.com</Typography>
              <div className="flex flex-row items-center justify-between w-[240px]">
                <div className="flex">
                  <Location /> <Typography sx={{ fontSize: 12, color: '#00B2D6', ml: 1 }}>Canada</Typography>
                </div>
                <div className="flex">
                  <RegistrationIcon /> <Typography sx={{ fontSize: 12, color: '#00B2D6', ml: 1 }}>Regd : 27 May 2014</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <Typography sx={{ fontSize: 20, color: '#00B2D6', fontWeight: 'bold' }}>Account information:</Typography>
        </div>
        <div className="flex w-full flex-row justify-between items-center">
          <div>
            <Typography sx={{ fontSize: 20, color: '#00B2D6', fontWeight: 'bold' }}>Name</Typography>
            <TextField
              sx={{
                mt: 1,
                display: 'flex',
                width: '400px',
                maxWidth: '400px',
                alignSelf: 'stretch',
                borderRadius: '4px',
                border: '1px solid #00B2D6',
                background: '#99E0EF',
                paddingY: '2px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none', 
                  },
                },
              }}
            />
          </div>
          <div>
            <Typography sx={{ fontSize: 20, color: '#00B2D6', fontWeight: 'bold' }}>Password</Typography>
            <div className="flex flex-row justify-center items-center">
              <CustomTextField
                sx={{
                  mt: 1,
                  display: 'flex',
                  width: '400px',
                  maxWidth: '400px',
                  alignSelf: 'stretch',
                  borderRadius: '4px',
                  border: '1px solid #00B2D6',
                  background: '#99E0EF',
                  paddingY: '2px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none', 
                    },
                  },
                }}
              />
              <div className="ml-4">
                <EditIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Profile;