import React, { createContext, useState } from "react";
import ReactVirtualizedTable from "../../components/DataTable/DataTable";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { LeftArrowIcon } from '../../components/LeftArrowIcon'
import { useNavigate } from "react-router-dom";
import BoyImage from "../../assets/BoyImage.png"
import EditIcon from "../../components/EditIcon";
import EmailBlue from "../../assets/EmailBlue.png"
import VerifiedIcon from "../../components/VerifiedIcon";
import PhoneBlue from "../../assets/PhoneBlue.png"
import NotVerified from "../../components/NotVerified";
import { OrderRejected } from "../../components/OrderRejected";
import { OrderBooked } from "../../components/OrderBooked"
import AccountUnblocked from "../../components/AccountUnblocked"
function RequesterDetails() {
    const navigate = useNavigate();
    const bgColors = ['yellow', '#B2F2FF', '#D00E11', '#A8FFA0', '#D6B7FF', '#B2F2FF'];
    const data = bgColors.map(bgColor => ({
        Request_ID: "PQREQ1",
        Created_At: "11/05/25",
        Service_Category: "Cleaning",
        Request_Stage: "Pro Selected",
        Commission_Status: <Typography sx={{ padding: 1, backgroundColor: bgColor }}>$ 1800</Typography>,
    }));
    const columns = [
        ["Request_ID", "Request ID", 150],
        ["Created_At", "Created At", 150],
        ["Service_Category", "Service Category", 150],
        ["Request_Stage", "Request Stage", 150],
        ["Commission_Status", "Commission Status", 500]
    ].map(([accessorKey, header, size]) => ({ accessorKey, header, size }));
    const orderStatusData = [
        { leftLine: false, rightLine: true, icon: <OrderBooked />, name: "Account Created", time: "05:07AM", date: "21Jul" },
        { leftLine: true, rightLine: true, icon: <OrderRejected />, name: "Account Blocked", time: "10:44", date: "22Jul" },
        { leftLine: true, rightLine: false, icon: <AccountUnblocked />, name: "Account Created", time: "05:07AM", date: "21Jul" }
    ];
    const OrderStatusCard = ({ icon, name, date, time, leftLine, rightLine }) => {
        return (
            <div className="p-1 flex justify-between items-center w-full">
                {leftLine ? (<div className="flex justify-center items-center w-full">
                    <div className="border-t border-[#00B2D6] border-dashed w-full" style={{ marginRight: '-20px', width: '70px', marginLeft: '-8px' }} ></div>
                </div>) : (<div className="flex justify-center items-center w-full">
                    <div style={{ marginRight: '-20px', width: '70px' }} ></div>
                </div>)}
                <div className="flex items-center flex-col justify-between w-full">
                    {icon}
                    <div className="mt-2">
                        <div
                            className="rounded-full h-5 w-5"
                            style={{ backgroundColor: "#00B2D6" }}
                        />
                    </div>
                    <div>
                        <Typography sx={{ fontSize: 6, color: '#00B2D6' }} >{name}</Typography>
                    </div>
                    <Typography sx={{ fontSize: 6 }} >{date}</Typography>
                    <Typography sx={{ fontSize: 6 }} >{time}</Typography>
                </div>
                {rightLine ? (<div className="flex justify-center items-center w-full">
                    <div className="border-t border-[#00B2D6] border-dashed " style={{ marginLeft: '-20px', width: '70px', marginRight: '-8px' }}></div>
                </div>) : (<div className="flex justify-center items-center w-full">
                    <div style={{ marginLeft: '-20px', width: '70px' }}></div>
                </div>)}
            </div>
        );
    };
    return (
        <div className="p-5 px-8 animate-fade flex flex-col gap-8">
            <div className="flex flex-row items-center justify-start">
                <Button >
                    <LeftArrowIcon />
                </Button>
                <p className="text-4xl font-Poppins font-semibold ml-4">Requester Details</p>
            </div>
            <div className="flex justify-center">
                <div>
                    <img src={BoyImage} alt="Requester Image" height="400" width="400" />
                </div>
                <div className="flex w-[390px] w-full pl-2 flex-col">

                    <div className="flex flex-row justify-between items-center mt-[-0.9rem]  ">
                        <Typography sx={{ fontSize: 36, fontWeight: 'bold' }} >Robert Downey</Typography>
                        <EditIcon />
                    </div>
                    <div>
                        <Typography sx={{
                            color: '#7E7E7E',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '20px',
                            paddingLeft: 0.5
                        }} >Home : Mississauga, ON, Canada</Typography>
                    </div>
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex-row items-center flex">
                            <img src={EmailBlue} style={{ height: 24, width: 24 }} /><Typography sx={{ paddingX: 0.5, fontSize: 13 }} >robertdowney@gmail.com</Typography>
                            <VerifiedIcon />
                        </div>
                        <div className="flex-row items-center flex">
                            <img src={PhoneBlue} style={{ height: 24, width: 24 }} /><Typography sx={{ paddingX: 0.5, fontSize: 13 }} >+1123456789</Typography>
                            <NotVerified />
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-center py-2 pt-10">
                        <Typography sx={{
                            color: '#00B2D6',
                            fontFamily: 'Inter',
                            fontSize: '17px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '16px',
                        }}>Last Order Posted</Typography>
                        <Typography sx={{
                            color: '#00B2D6',
                            fontFamily: 'Inter',
                            fontSize: '17px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '16px',
                        }}>Total Posted Till Date</Typography>
                    </div>
                    <div className="flex flex-row justify-between items-center">
                        <Typography sx={{
                            color: '#00B2D6',
                            fontFamily: 'Inter',
                            fontSize: '17px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '16px',
                        }}>14 Sep 2024</Typography>
                        <Typography sx={{
                            color: '#00B2D6',
                            fontFamily: 'Inter',
                            fontSize: '17px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '16px',
                        }}>2,790</Typography>
                    </div>
                </div>
                <div className="flex w-[500px] w-full ml-2 flex-col items-center" >
                    <Typography sx={{
                        color: '#00B2D6',
                        fontFeatureSettings: "'liga' off, 'clig' off",
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                        marginBottom: 2,
                        mt: -3
                    }}>
                        Requestor History
                    </Typography>
                    <div className="border border-[#00B2D6] px-5  shadow-md bg-white flex justify-between items-center w-full">
                        {orderStatusData.map((status, index) => (
                            <OrderStatusCard key={index} {...status} />
                        ))}
                    </div>
                    <div className="w-full h-full background bg-[#00B2D6] mb-[10px] mt-[10px] flex items-center justify-center"  >
                        <div className="w-[200px] h-full justify-between items-center flex-row flex">
                            <div className="flex items-center justify-center w-full h-full flex-col" >
                                <Typography sx={{ fontSize: 10, color: 'white' }}>Average Rating</Typography>
                                <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: 'white' }}>4.5/5</Typography>
                            </div>
                            <div className="flex items-center justify-center w-full h-full flex-col">
                                <Typography sx={{ fontSize: 10, color: 'white' }}>Last Request Rating</Typography>
                                <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: 'white' }}>3/5</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="flex justify-between flex-row w-full">
                    <div>
                        <Typography sx={{ color: "#00B2D6", fontSize: 32 }}>Requests</Typography>
                        <Typography sx={{ color: '#7C7D7F', fontSize: 16 }} >Recent Requests And Their Status</Typography>
                    </div>
                    <div>
                        <Button sx={{ fontSize: 16, color: '#00B2D6', mt: 5 }}>VIEW All</Button>
                    </div>
                </div>
                <ReactVirtualizedTable data={data} columnsfrom={columns} />
            </div>
        </div>
    )
}
export default RequesterDetails;