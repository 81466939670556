import React from "react";
import ReactVirtualizedTable from "../../components/DataTable/DataTable";
import { useNavigate } from "react-router-dom";
import { useServices } from "../../hooks/useServices";
import EditService from "./EditService";

function Services() {
  const { columns, data, edit, setedit } = useServices();
  const navigate = useNavigate();
  if (edit) return <EditService edit={edit} setedit={setedit} />;
  return (
    <div className="p-5 px-8 font-Poppins animate-fade flex gap-8">
      <div className="flex flex-col w-full gap-3">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <p
              onClick={() => navigate(-1)}
              className="text-4xl cursor-pointer font-semibold"
            >
              &larr;
            </p>
            <p className="text-4xl font-semibold">Services</p>
          </div>
          <button
            onClick={() => navigate("add-service")}
            className="p-[6px] px-4 rounded-md bg-light-background-neww text-white font-semibold"
          >
            Add Service
          </button>
        </div>
        <ReactVirtualizedTable data={data} columnsfrom={columns} />
      </div>
    </div>
  );
}

export default Services;
