import React from "react";
import ReactVirtualizedTable from "../../components/DataTable/DataTable";
import { useNavigate } from "react-router-dom";
import { useCategory } from "../../hooks/useCategory";
import EditCategory from "./EditCategory";

function Category() {
  const { columns, data, setedit, edit } = useCategory();
  const navigate = useNavigate();

  if (edit) return <EditCategory setedit={setedit} edit={edit} />;

  return (
    <div className="p-5 px-8 font-Poppins animate-fade flex gap-8">
      <div className="flex flex-col w-full gap-3">
        <div className="flex justify-between items-center">
          <p className="text-4xl font-semibold">Category</p>
          <button
            onClick={() => navigate("add-category")}
            className="p-[6px] px-4 rounded-md bg-light-background-neww text-white font-semibold"
          >
            Add Category
          </button>
        </div>
        {/* <SortableTable loading={loading} tableData={data} /> */}
        <ReactVirtualizedTable data={data} columnsfrom={columns} />
      </div>
    </div>
  );
}

export default Category;
