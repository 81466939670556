import React, { useEffect, useMemo, useState } from "react";
import { retrieveData } from "../helper/functions";
import { useParams } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { IoIosStarOutline, IoMdStar } from "react-icons/io";
import { $crud } from "../utils/CrudFactory";

export function useServices() {
  const [datas, setdata] = useState([]);
  const [edit, setedit] = useState(false);
  const { catId } = useParams();
  const [changeStar, setchangeStar] = useState(false);

  async function handleStar(id) {
    try {
      await $crud.put("admin/service/star", {
        _id: id,
      });
      setchangeStar((changeStar) => !changeStar);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    async function fetch() {
      try {
        const resdata = await retrieveData(
          `admin/services?categoryId=${catId}`
        );
        setdata(resdata.services);
      } catch (e) {
        console.error(e);
      }
    }
    fetch();
  }, [catId, changeStar, edit]);

  const data = useMemo(() => {
    return datas.map((obj) => {
      obj.statusshow = (
        <p
          className={`bg-green-300 text-green-900 ${
            obj.status !== "Approved" && "text-red-900 bg-red-300"
          } text-center p-[2px] px-2 w-fit text-xs rounded-lg`}
        >
          {obj.status}
        </p>
      );
      obj.starred = obj.star ? (
        <IoMdStar
          className="w-5 h-5 cursor-pointer"
          onClick={() => handleStar(obj._id)}
        />
      ) : (
        <IoIosStarOutline
          className="w-5 h-5 cursor-pointer"
          onClick={() => handleStar(obj._id)}
        />
      );

      obj.edit = (
        <FaEdit
          className="w-5 h-5 cursor-pointer"
          onClick={() => setedit(obj)}
        />
      );
      return obj;
    });
  }, [datas]);

  const columns = [
    {
      accessorKey: "starred",
      header: "Home Page",
    },
    {
      accessorKey: "_id",
      header: "Id",
    },
    {
      accessorKey: "category",
      header: "Name",
    },
    {
      accessorKey: "statusshow",
      header: "Status",
    },
    {
      accessorKey: "categoryId.name",
      header: "Category",
    },
    {
      accessorKey: "edit",
      header: "Edit",
    },
  ];

  return { columns, data, edit, setedit };
}
