import React from "react";
import ReactVirtualizedTable from "../../components/DataTable/DataTable";

function Leads() {
  const data = [
    {
      name: {
        firstName: "Joe",
        lastName: "Doe",
      },
      address: "566 Brakus Inlet",
      city: "South Linda",
      state: "West Virginia",
    },
    {
      name: {
        firstName: "Kevin",
        lastName: "Vandy",
      },
      address: "722 Emie Stream",
      city: "Lincoln",
      state: "Nebraska",
    },
    {
      name: {
        firstName: "Joshua",
        lastName: "Rolluffs",
      },
      address: "32188 Larkin Turnpike",
      city: "Omaha",
      state: "Nebraska",
    },
    {
      name: {
        firstName: "Joshua",
        lastName: "Rolluffs",
      },
      address: "32188 Larkin Turnpike",
      city: "Omaha",
      state: "Nebraska",
    },
    {
      name: {
        firstName: "Joshua",
        lastName: "Rolluffs",
      },
      address: "32188 Larkin Turnpike",
      city: "Omaha",
      state: "Nebraska",
    },
  ];

  const columns = [
    {
      accessorKey: "name.firstName",
      header: "First Name",
      size: 150,
    },
    {
      accessorKey: "name.lastName",
      header: "Last Name",
      size: 150,
    },
    {
      accessorKey: "address",
      header: "Address",
      size: 200,
    },
    {
      accessorKey: "city",
      header: "City",
      size: 150,
    },
    {
      accessorKey: "state",
      header: "State",
      size: 150,
    },
  ];

  return (
    <div className="p-5 px-8 animate-fade flex gap-8">
      <div className="flex  flex-col w-full gap-3">
        <p className="text-4xl font-Poppins font-semibold">Leads</p>
        <ReactVirtualizedTable data={data} columnsfrom={columns} />
      </div>
    </div>
  );
}

export default Leads;
