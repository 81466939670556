import { Avatar } from "@mui/material";
import React from "react";
import { IoIosSearch } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

export function Header() {
  // const [side, setSide] = useState(false);
  const user = JSON.parse(localStorage.getItem("pqckleAdmin"));
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <header className=" sticky bg-white z-50 top-2 mt-2 justify-between items-center rounded-xl px-10 flex h-20 w-full">
      <div className="flex items-center gap-2">
        {/* <button
          className="md:hidden block"
          onClick={() => {
            setSide(true);
          }}
        >
          slide
        </button> */}
        <div className="relative ">
          <input
            type="text"
            placeholder="Search here..."
            className="border outline-none focus:ring-1 ring-light-background-neww h-12 px-3 pb-1 pr-12 w-52 transition-all duration-500 sm:w-72 md:w-[450px] md:focus:w-[500px] rounded-lg"
          />
          <IoIosSearch className="w-6 h-6 absolute top-3 right-5" />
        </div>
      </div>
      <div className="flex gap-2 items-center">
        {/* <p>Hello {user.name}</p> */}
        <Link to="/app/profile">
          <Avatar />
        </Link>
      </div>

      {/* <div
        className={`inset-0 fixed z-50 ${
          !side && "-translate-x-full"
        } transition-transform duration-300 bg-slate-200`}
      > 
        <NewSidebar />
      </div> */}
    </header>
  );
}
