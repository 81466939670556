import React, { useEffect, useMemo, useState } from "react";
import { retrieveData } from "../helper/functions";
import { format } from "date-fns";
import { IoEye } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

export function useCategory() {
  const [datas, setdata] = useState([]);
  const [edit, setedit] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    async function fetch() {
      try {
        const resdata = await retrieveData("admin/categories");
        setdata(resdata.categories);
      } catch (e) {
        console.error(e);
      }
    }
    fetch();
  }, [edit]);

  const data = useMemo(() => {
    return datas.map((obj) => {
      obj.createdAt = format(new Date(obj.createdAt), "MM/dd/yyyy");
      obj.updatedAt = format(new Date(obj.createdAt), "MM/dd/yyyy");
      obj.view = (
        <IoEye
          className="w-5 h-5 cursor-pointer"
          onClick={() => navigate(`${obj._id}/services`)}
        />
      );
      obj.edit = (
        <FaEdit
          className="w-5 h-5 cursor-pointer"
          onClick={() => setedit(obj)}
        />
      );
      return obj;
    });
  }, [datas]);

  const columns = [
    {
      accessorKey: "_id",
      header: "Id",
    },
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "createdAt",
      header: "Created At",
    },
    {
      accessorKey: "updatedAt",
      header: "Updated At",
    },
    {
      accessorKey: "view",
      header: "View Services",
    },
    {
      accessorKey: "edit",
      header: "Edit",
    },
  ];

  return { columns, data, setedit, edit };
}
