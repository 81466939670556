import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { API_URL } from "../../constants";
import { enqueueSnackbar } from "notistack";
import { CustomButton } from "../../components";
import { useNavigate } from "react-router-dom";
import HandBImage from "../../assets/HandBImage.png"
import DoubleInvertedComma from "../../components/DoubleInvertedComma";
import LeftArrow from "../../components/LeftArrow";
import RightArrow from "../../components/RightArrow";
import SuccessIconSvg from "../../components/SuccessIconSvg";
import { ImageContent } from "../../components/ImageContent ";
export function Success() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const initialValues = {
        email: "",
    };
    const onSubmit = async (values) => {
        setIsSubmitting(true);
        try {
            const response = await axios.post(`${API_URL}create/admin/resend-otp`, {
                email: values.email,
            });
            const { type, message } = response.data;
            if (type === "error") {
                enqueueSnackbar(message, {
                    variant: type,
                    persist: true,
                });
                setIsSubmitting(false);
            }
            if (type === "success") {
                enqueueSnackbar(message, {
                    variant: type,
                });
                navigate("/reset-password", { state: { email: values.email } });
            }
        } catch (error) {
            console.error("Error during OTP resend:", error);
            enqueueSnackbar("Failed to resend OTP. Please try again.", {
                variant: "error",
            });
            setIsSubmitting(false);
        }
    };
    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email format.").required("Required!"),
    });
    const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
        useFormik({
            initialValues,
            onSubmit,
            validationSchema,
        });
    return (
        <Grid container sx={{ height: "100vh" }}>
            <ImageContent description="Lorem ipsum dolor sit amet, conse elit, sed do eiusmod tempor i ut labore et dolore magna aliqua.Utenim ad minim veniam, grdrerlaboris nisi ut aliquip ex ea commodo consequat." />         
            <Grid
                item
                lg={6}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    flexDirection: "column",
                    height: "100%",
                }}
            >
                <Box
                    sx={{
                        maxWidth: {
                            xs: "270px",
                            md: "300px",
                            lg: "512px",
                        },
                        padding: "16px", 
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', alignContent: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, justifyContent: 'center', flexDirection: 'row', alignContent: 'center', mb: 2 }}>
                            <Typography
                                variant="body2"
                                color="black"
                            >
                                <SuccessIconSvg />
                            </Typography>
                        </Box>
                        <Typography
                            onClick={() =>
                                navigate("/forgot-password", {
                                    state: { changeAccPassword: false },
                                })
                            }
                            sx={{
                                cursor: "pointer",
                                fontWeight: 500,
                                textAlign: "right",
                                mb: 2,
                            }}
                            variant="body2"
                            color="#00B2D6"
                        >
                        </Typography>
                    </Box>
                    <Typography
                        variant="h4"
                        sx={{
                            color: "black",
                            fontWeight: "bold",
                            mb: 3,
                            textAlign: "left",
                        }}
                    >
                        Your Password<br />Successfully Changed
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            color: "#00B2D6",
                            mb: 3,
                            textAlign: "left",
                            marginTop: '-5%',
                            fontSize: 14,
                        }}
                    >
                        Login in to your account with your new password
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Box sx={{paddingTop:3}}>
                            <CustomButton type="submit" disabled={isSubmitting} BorderRadius={"100px"} onClick={() => navigate('/login')} >
                                Back To Login
                            </CustomButton>
                            <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 2 }}  >
                                <Typography
                                    sx={{
                                        textAlign: "center",
                                        fontSize: "14px",
                                        fontStyle: "normal",

                                        flexDirection: 'row'
                                    }}
                                    variant="body2"
                                    color="black"
                                >
                                    Taking you to login
                                </Typography>
                                <Typography sx={{
                                    color: 'rgba(0, 178, 214, 1)', textAlign: "center",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    flexDirection: 'row', paddingLeft: 1
                                }}>   00:03</Typography>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Grid>
        </Grid >
    );
}