import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import ProtectedRoute from "./ProtectedRoute";
import { Footer, Header, Sidebar } from "../components";
import {
  Home,
  LoginPage,
  ComingSoon,
  ForgotPassword,
  FASetup,
  LogoutPage,
  ResetPassword,
  Requester,
} from "../pages";
import { Box } from "@mui/material";
import NewSidebar from "../components/NewSidebar";
import Category from "../pages/category/Category";
import Leads from "../pages/leads/Leads";
import Buyers from "../pages/buyers/Buyers";
import NewRequester from "../pages/requester/Requester";
import Review from "../pages/review/Review";
import Profile from "../pages/profile/Profile";
import AddCategory from "../pages/category/AddCategory";
import Services from "../pages/services/Services";
import AddService from "../pages/services/AddService";
import { LoginWithEmailOtp } from "../pages/auth/LoginWithEmailOtp";
import { OtpVerificatiom } from "../pages/auth/OtpVerificatiom";
import { SetNewPassword } from "../pages/auth/SetNewPassword";
import { OtpConfirmPassword } from "../pages/auth/OtpConfirmPassword";
import { Success } from "../pages/auth/Success";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Route for Login Page */}
        <Route
          path="/"
          element={
            <PublicRoute>
              <ComingSoon />
              {/* <Navigate to="/login" /> */}
            </PublicRoute>
          }
        />

        <Route
          path="/login"
          element={
            <PublicRoute>
              <LoginPage />
            </PublicRoute>
          }
        />

        <Route
          path="/LoginWithEmailOtp"
          element={
            <PublicRoute>
              <LoginWithEmailOtp />
            </PublicRoute>
          }
        />

        <Route
          path="/OtpVerificatiom"
          element={
            <PublicRoute>
              <OtpVerificatiom />
            </PublicRoute>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />

        <Route
          path="/SetNewPassword"
          element={
            <PublicRoute>
              <SetNewPassword />
            </PublicRoute>
          }
        />

        <Route
          path="/Success"
          element={
            <PublicRoute>
              <Success />
            </PublicRoute>
          }
        />

        <Route
          path="/OtpConfirmPassword"
          element={
            <PublicRoute>
              <OtpConfirmPassword />
            </PublicRoute>
          }
        />


        <Route
          path="/authenticator"
          element={
            <PublicRoute>
              <FASetup />
            </PublicRoute>
          }
        />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* Protected Routes */}
        <Route
          path="/app/*"
          element={
            <ProtectedRoute>
              <div className="flex gap-2">
                <div>
                  {/* <Sidebar /> */}
                  <NewSidebar />
                </div>

                <div className="flex w-full flex-col h-full">
                  <Header />
                  <Box sx={{ flexGrow: 1, overflow: "auto" }}>
                    <Routes>
                      <Route
                        index
                        element={<Navigate replace to="dashboard" />}
                      />
                      <Route path="dashboard" element={<Home />} />
                      <Route path="category" element={<Category />} />
                      <Route
                        path="category/add-category"
                        element={<AddCategory />}
                      />
                      <Route
                        path="category/:catId/services"
                        element={<Services />}
                      />
                      <Route
                        path="category/:catId/services/add-service"
                        element={<AddService />}
                      />
                      <Route path="leads" element={<Leads />} />
                      <Route path="requesters" element={<NewRequester />} />
                      <Route path="buyers" element={<Buyers />} />
                      <Route path="reviews" element={<Review />} />
                      <Route path="logout" element={<LogoutPage />} />
                      <Route path="profile" element={<Profile />} />
                    </Routes>
                  </Box>
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        {/* Fallback Route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
