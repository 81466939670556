import React from 'react';
import { Box, Grid, Typography } from "@mui/material";
import HandBImage from "../assets/HandBImage.png";
import DoubleInvertedComma from "./DoubleInvertedComma";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";

export const ImageContent = ({ description, showArrows = true }) => (
    <Grid
        item
        lg={6}
        sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            height: "100%",
        }}
    >
        <div className="flex w-full h-full relative">
            <img src={HandBImage} className="h-full w-full object-contain absolute" alt="Background" />
            <div className="bg-gradient-to-t from-light-background-neww absolute w-full h-full">
                <div style={{ alignContent: 'start', marginTop: 480 }} className="w-full">
                    <div style={{ marginRight: 700 }}>
                        <DoubleInvertedComma />
                    </div>
                </div>
                <div style={{ marginLeft: 22, height: 120 }}>
                    <Typography sx={{ color: 'white', fontWeight: '400', fontSize: 18, textAlign: 'left' }}>
                        {description}
                    </Typography>
                </div>
                <div className="w-full">
                    {showArrows && (
                        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'end', alignItems: 'center', gap: "10px", paddingRight: '30px' }}>
                            <LeftArrow style={{ color: 'white' }} />
                            <RightArrow style={{ color: 'white' }} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    </Grid>
);


// Example usage in OtpConfirmPassword.jsx (no changes needed):
export function OtpConfirmPassword() {
    // ... other code

    return (
        <Grid container sx={{ height: "100vh" }}>
            <ImageContent description="Lorem ipsum dolor sit amet, conse elit, sed do eiusmod tempor i ut labore et dolore magna aliqua.Utenim ad minim veniam, grdrerlaboris nisi ut aliquip ex ea commodo consequat." />
            
        </Grid>
    );
}