import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import { CustomButton, CustomTextField } from "../../components";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../hooks/useLogin";
import HandBImage from "../../assets/HandBImage.png"
import DoubleInvertedComma from "../../components/DoubleInvertedComma";
import LeftArrow from "../../components/LeftArrow";
import RightArrow from "../../components/RightArrow";
import { ImageContent } from "../../components/ImageContent ";
export function SetNewPassword() {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { initialValues, isSubmitting, onSubmit, validationSchema } =
    useLogin();
  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });
  function HandleSaveNewPassword() {
    if (newPassword === confirmPassword) {
      if (newPassword.length !== 0 && confirmPassword.length !== 0) {
        navigate("/Success")
      }
    }
  }
  return (
    <Grid container sx={{ height: "100vh" }}>
      <ImageContent description="Lorem ipsum dolor sit amet, conse elit, sed do eiusmod tempor i ut labore et dolore magna aliqua.Utenim ad minim veniam, grdrerlaboris nisi ut aliquip ex ea commodo consequat." />
      <Grid
        item
        lg={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            maxWidth: {
              xs: "270px",
              md: "300px",
              lg: "512px",
            },
            padding: "16px",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "black",
              fontWeight: "bold",
              mb: 3,
              textAlign: "left",
            }}
          >
            Set New Password
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#00B2D6",
              mb: 3,
              textAlign: "left",
              marginTop: '-5%',
              fontSize: 14,
            }}
          >
            Enter your new password to complete the reset process
          </Typography>
          <form onSubmit={handleSubmit}>
            <Typography
              sx={{
                mb: 1,
                textAlign: "left",
                color: '#2E3139',
                fontSize: 12,
                mt: 5
              }}
            >
              New Password
            </Typography>
            <CustomTextField
              name="password"
              placeholder="Enter Your Password"
              id="NewPassword"
              type="password"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              onBlur={handleBlur}
              error={touched.password && !!errors.password}
              helperText={touched.password && errors.password}
            />
            <Typography
              sx={{
                mb: 1,
                textAlign: "left",
                color: '#2E3139',
                fontSize: 12,
              }}
            >
              Confirm New Password
            </Typography>
            <CustomTextField
              name="password"
              placeholder="Enter Your Password"
              id="Confirmpassword"
              type="password"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              onBlur={handleBlur}
              error={touched.password && !!errors.password}
              helperText={touched.password && errors.password}
            />
            <CustomButton type="submit" disabled={isSubmitting} BorderRadius={"100px"} onClick={() => HandleSaveNewPassword()} >
              Save New Password
            </CustomButton>
            <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 3 }}  >
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontStyle: "normal",
                  flexDirection: 'row'
                }}
                variant="body2"
                color="black"
              >
                Remember old password?
              </Typography>
              <Typography sx={{
                color: 'rgba(0, 178, 214, 1)', textAlign: "center",
                fontSize: "14px",
                fontStyle: "normal",
                flexDirection: 'row', paddingLeft: 1
              }}>   Login</Typography>
            </Box>
          </form>
        </Box>
      </Grid>
    </Grid >
  );
}