import * as React from 'react';
import { SvgIcon } from '@mui/material';

export const OrderRejected = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 0C19.4037 0 25 5.59625 25 12.5C25 19.4037 19.4037 25 12.5 25C5.59625 25 0 19.4037 0 12.5C0 5.59625 5.59625 0 12.5 0ZM17 6.5C16.8687 6.40151 16.7192 6.32985 16.5602 6.28911C16.4012 6.24837 16.2357 6.23935 16.0732 6.26256C15.9107 6.28578 15.7544 6.34077 15.6131 6.42441C15.4719 6.50804 15.3485 6.61868 15.25 6.75L12.5 10.4163L9.75 6.75C9.65151 6.61868 9.52812 6.50804 9.38687 6.42441C9.24562 6.34077 9.08928 6.28578 8.92678 6.26256C8.76427 6.23935 8.59879 6.24837 8.43977 6.28911C8.28076 6.32985 8.13132 6.40151 8 6.5C7.86868 6.59849 7.75804 6.72188 7.67441 6.86313C7.59077 7.00438 7.53578 7.16072 7.51256 7.32322C7.48935 7.48573 7.49837 7.65121 7.53911 7.81023C7.57985 7.96924 7.65151 8.11868 7.75 8.25L10.9375 12.5L7.75 16.75C7.55109 17.0152 7.46568 17.3486 7.51256 17.6768C7.55945 18.005 7.73478 18.3011 8 18.5C8.26522 18.6989 8.59859 18.7843 8.92678 18.7374C9.25496 18.6906 9.55109 18.5152 9.75 18.25L12.5 14.5837L15.25 18.25C15.4489 18.5152 15.745 18.6906 16.0732 18.7374C16.4014 18.7843 16.7348 18.6989 17 18.5C17.2652 18.3011 17.4406 18.005 17.4874 17.6768C17.5343 17.3486 17.4489 17.0152 17.25 16.75L14.0625 12.5L17.25 8.25C17.3485 8.11868 17.4202 7.96924 17.4609 7.81023C17.5016 7.65121 17.5107 7.48573 17.4874 7.32322C17.4642 7.16072 17.4092 7.00438 17.3256 6.86313C17.242 6.72188 17.1313 6.59849 17 6.5Z" fill="#EB0800" />
        </svg>

    )
}