import React, { useState } from "react";
import { $crud } from "../../utils/CrudFactory";

function EditCategory({ setedit, edit }) {
  const [newName, setnewName] = useState(null);
  async function submit() {
    try {
      const data = await $crud.put("admin/category", {
        categoryId: edit._id,
        newName,
      });
      setedit(false);
    } catch (e) {
      console.error(e);
    }
  }
  return (
    <div className="p-5 px-8 animate-fade flex gap-8">
      <div className="flex flex-col w-full gap-3">
        <div className="flex font-Poppins gap-2 items-center">
          <p
            onClick={() => setedit(false)}
            className="text-4xl cursor-pointer font-semibold"
          >
            &larr;
          </p>
          <p className="text-4xl font-semibold">Edit Category</p>
        </div>
        <div className="bg-stone-100 flex flex-col justify-between font-Poppins border p-10 h-[480px] rounded-2xl">
          <div className="flex flex-col gap-2">
            <label className="text-xl font-semibold">Category Name</label>
            <input
              autoFocus
              type="text"
              onChange={(e) => setnewName(e.target.value)}
              value={newName}
              defaultValue={edit.name}
              className="w-96 rounded-md border outline-none px-4 focus:ring-1 ring-light-text-neww h-10"
            />
          </div>
          <div className="flex gap-3 justify-end items-center">
            <button
              onClick={() => {
                setedit(false);
              }}
              className="bg-red-500 rounded-md text-white p-2 px-4"
            >
              Cancel
            </button>
            <button
              onClick={submit}
              className="bg-light-background-neww rounded-md text-white p-2 px-4"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCategory;
