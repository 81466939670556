import * as React from 'react';
import { SvgIcon } from '@mui/material';

const RightArrow = (props) => (
  <SvgIcon {...props}>
    <path d="M21.0001 7.99976H1.00012" stroke="white" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.7504 14.25C14.7504 14.25 21.0002 9.647 21.0002 8C21.0002 6.353 14.7502 1.75 14.7502 1.75" stroke="white" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default RightArrow;