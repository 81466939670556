import { useTheme } from "@emotion/react";
import { DashboardRounded } from "@mui/icons-material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/logo.jpeg";
import { BsGrid1X2Fill } from "react-icons/bs";

function NewSidebar() {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current path
  const theme = useTheme();

  const navLinks = [
    {
      label: "Home",
      route: "/app/dashboard",
      icon: <DashboardRounded />,
    },
    {
      label: "Leads",
      route: "/app/leads",
      icon: <DashboardRounded />,
    },
    {
      label: "Category",
      route: "/app/category",
      icon: <DashboardRounded />,
    },
    {
      label: "Requesters",
      route: "/app/requesters",
      icon: <DashboardRounded />,
    },
    {
      label: "Buyers",
      route: "/app/buyers",
      icon: <DashboardRounded />,
    },
    {
      label: "Reviews",
      route: "/app/reviews",
      icon: <DashboardRounded />,
    },
    {
      label: "Logout",
      route: "/app/logout",
      icon: <DashboardRounded />,
    },
  ];
  return (
    <div className="h-screen w-40 md:w-60 border-r bg-white">
      <div className="flex flex-col items-center gap-1">
        <img src={logo} className="md:w-32 w-28 h-28 md:h-32" alt="" />
        <p className="uppercase md:text-base text-sm text-light-background-neww font-semibold ">
          Services
        </p>
        <div className="flex gap-[18px] items-center flex-col">
          {navLinks.map((obj, i) => {
            return (
              <button
                onClick={() => {
                  navigate(obj.route);
                }}
                key={i}
                className={`${
                  location.pathname.includes(obj.route)
                    ? "bg-light-background-neww"
                    : "bg-[#a0d2e5]"
                } flex items-center gap-3 active:scale-95 rounded-br-2xl font-semibold active:bg-dark-background-accent text-xs md:text-base text-white w-full p-2 px-3 md:px-10 rounded-md`}
              >
                <BsGrid1X2Fill className="w-5 h-5" /> {obj.label}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default NewSidebar;
