import * as React from 'react';
import { SvgIcon } from '@mui/material';

const Location = (props) => (
    <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.41483 0.5C3.78617 0.5 0.0305176 3.52391 0.0305176 7.25C0.0305176 13.25 8.41483 21.5 8.41483 21.5C8.41483 21.5 16.7991 13.25 16.7991 7.25C16.7991 3.52391 13.0435 0.5 8.41483 0.5ZM8.41483 11C7.75153 11 7.10312 10.8241 6.5516 10.4944C6.00009 10.1648 5.57023 9.69623 5.31639 9.14805C5.06256 8.59987 4.99614 7.99667 5.12555 7.41473C5.25495 6.83279 5.57436 6.29824 6.04339 5.87868C6.51242 5.45912 7.10999 5.1734 7.76055 5.05764C8.41111 4.94189 9.08543 5.0013 9.69825 5.22836C10.3111 5.45542 10.8348 5.83994 11.2034 6.33329C11.5719 6.82664 11.7686 7.40666 11.7686 8C11.7676 8.79538 11.4139 9.55794 10.7852 10.1204C10.1565 10.6828 9.304 10.9991 8.41483 11Z" fill="#00B2D6" />
    </svg>

)


export default Location;