import * as React from 'react';
import { SvgIcon } from '@mui/material';

const DoubleInvertedComma = (props) => (
  <SvgIcon {...props}>
    <path d="M13.9164 8C13.9164 9.88562 13.9164 10.8284 14.4987 11.4142C15.081 12 16.0182 12 17.8925 12C19.7669 12 20.7041 12 21.2864 11.4142C21.8687 10.8284 21.8687 9.88562 21.8687 8C21.8687 6.11438 21.8687 5.17157 21.2864 4.58579C20.7041 4 19.7669 4 17.8925 4C16.0182 4 15.081 4 14.4987 4.58579C13.9164 5.17157 13.9164 6.11438 13.9164 8Z" fill="white" stroke="white" strokeWidth="1.5" />
    <path d="M13.9164 7V11.4821C13.9164 15.4547 16.4171 18.8237 19.8806 20" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M1.98807 8C1.98807 9.88562 1.98807 10.8284 2.57037 11.4142C3.15267 12 4.08985 12 5.96419 12C7.83853 12 8.7757 12 9.358 11.4142C9.94031 10.8284 9.94031 9.88562 9.94031 8C9.94031 6.11438 9.94031 5.17157 9.358 4.58579C8.7757 4 7.83853 4 5.96419 4C4.08985 4 3.15267 4 2.57037 4.58579C1.98807 5.17157 1.98807 6.11438 1.98807 8Z" fill="white" stroke="white" strokeWidth="1.5" />
    <path d="M1.98806 7V11.4821C1.98806 15.4547 4.48874 18.8237 7.95224 20" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
  </SvgIcon>
);

export default DoubleInvertedComma;