import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import login from "../../assets/login.avif";
import logo from "../../assets/logo.jpeg";
import { useFormik } from "formik";
import { CustomButton, CustomTextField } from "../../components";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../hooks/useLogin";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import HandBImage from "../../assets/HandBImage.png"
import DoubleInvertedComma from "../../components/DoubleInvertedComma";
import LeftArrow from "../../components/LeftArrow";
import RightArrow from "../../components/RightArrow";

export function LoginWithEmailOtp() {
  const navigate = useNavigate();



  const [show, setShow] = useState(false);

  const { initialValues, isSubmitting, onSubmit, validationSchema } =
    useLogin();

  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });

  return (
    <Grid container sx={{ height: "100vh" }}>
     
      <Grid
        item
        lg={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          height: "100%", // Match parent height
          //boxShadow: "0px 4px 10px 0px #673C8E",
        }}
      >

        <div className="flex w-full h-full relative"  >
          <img src={HandBImage} className="h-full w-full object-contain absolute" />
          <div className="bg-gradient-to-t from-light-background-neww absolute w-full h-full ">
            <div style={{ alignContent: 'start', marginTop: 480 }} className="w-full">
              <div style={{ marginRight: 700 }}>
                <DoubleInvertedComma />
              </div>
            </div>
            <div style={{ marginLeft: 22, height: 120 }}>
              <Typography sx={{ color: 'white', fontWeight: '400', fontSize: 18, textAlign: 'left' }} >
                Lorem ipsum dolor sit amet, conse elit, sed do eiusmod tempor i ut labore et dolore magna aliqua.Utenim ad minim veniam, grdrerlaboris nisi ut aliquip ex ea commodo consequat.
              </Typography>
            </div>
            <div style={{}} className="w-full">
              <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'end', alignItems: 'center', gap: "10px", paddingRight: '30px' }}>
                <LeftArrow style={{ color: 'white' }} /><RightArrow style={{ color: 'white' }} />
              </div>
            </div>
          </div>
        </div>


      </Grid>
      <Grid
        item
        lg={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            maxWidth: {
              xs: "270px",
              md: "300px",
              lg: "512px",
            },
            padding: "16px", // Optional: Add padding for better appearance
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "black",
              fontWeight: "bold",
              mb: 3,
              textAlign: "left",
            }}
          >
            Welcome Back
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#00B2D6",
              mb: 3,
              textAlign: "left",
              marginTop: '-5%',
              fontSize: 14,

            }}
          >
            Login to your account using email
          </Typography>
          <form onSubmit={handleSubmit}>

            <Typography
              sx={{
                mb: 1,
                textAlign: "left",
                color: '#2E3139',
                //marginTop:5,
                fontSize: 12,
                //marginBottom:-0.1
                mt: 5
              }}
            >
              Enter Your Email Address
            </Typography>
            <CustomTextField
              name="email"
              placeholder="example@example.com"
              //label="Email"
              id="email"
              type="text"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
            />
            

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', alignContent: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, justifyContent: 'center', flexDirection: 'row', alignContent: 'center', mb: 2 }}>
                <Typography
                  onClick={() => setShow(!show)}
                  sx={{
                    cursor: "pointer",
                    fontWeight: 500,
                    textAlign: "right",
                  }}
                  variant="body2"
                  color="black"
                >
                  {show ? (<CheckBoxIcon sx={{ color: "#00B2D6" }} />) : (<CheckBoxOutlineBlankOutlinedIcon sx={{ color: "#00B2D6" }} />)}


                  Remember me
                </Typography>
              </Box>
              <Typography
                onClick={() =>
                  navigate("/forgot-password", {
                    state: { changeAccPassword: false },
                  })
                }
                sx={{
                  cursor: "pointer",
                  fontWeight: 500,
                  textAlign: "right",
                  mb: 2,
                }}
                variant="body2"
                color="#00B2D6"
              >
                Login With Password
              </Typography>
            </Box>


            <CustomButton type="submit" disabled={isSubmitting} BorderRadius={"100px"} onClick={()=> navigate('/OtpVerificatiom')} >
              SEND AN OTP
            </CustomButton>

          </form>
        </Box>
      </Grid>
    </Grid >
  );
}
