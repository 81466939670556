export const datatableStyle = {
  muiTableContainerProps: {
    sx: {
      minHeight: "300px",
      maxHeight: "420px", // Set a fixed height for scrolling
      overflowY: "auto", // Enable vertical scrolling
      position: "relative", // Ensure relative positioning
    },
  },
  muiTableHeadCellProps: {
    sx: {
      backgroundColor: "#02B2D6", // Set a green background for the header
      color: "white",
      fontWeight: "500",
      fontSize: "14px",
      fontFamily: "Segoe UI, sans-serif", // Custom font
      textTransform: "capitalize", // No uppercase text
      padding: "12px", // Add padding
    },
  },
  muiTableHeadProps: {
    sx: {
      position: "sticky", // Ensure the header is sticky
      top: 0, // Stick to the top of the container
      zIndex: 3, // Higher zIndex to stay above rows
      backgroundColor: "#4CAF50", // Header background color
      borderBottom: "2px solid #ddd", // Thin border
    },
  },
  muiTableBodyCellProps: {
    sx: {
      fontFamily: "Quicksand, sans-serif", // Friendly and rounded font
      fontSize: "15px",
      color: "#5f6368", // Soft gray color for the text
      padding: "12px",
      "&:hover": {
        backgroundColor: "#f1f1f1", // Slightly lighter gray on hover
      },
    },
  },
  muiTableBodyRowProps: {
    sx: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f7f7f7", // Light beige for odd rows
      },
      "&:nth-of-type(even)": {
        backgroundColor: "#ffffff", // Plain white for even rows
      },
    },
  },
};
