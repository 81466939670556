import React, { useState } from "react";
import ReactVirtualizedTable from "../../components/DataTable/DataTable";
import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Button, styled } from "@mui/material";
import Filter from "../../assets/Filter.png";
import RequesterDetails from "./RequesterDetails";
import { useContext } from "react";

function Requester() {

  const [show, setShow] = useState(false);

  const StyledButton = styled(Button)(({ theme }) => ({
    width: '100px',
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px', // Or 2.3
    backgroundColor: '#074F24'
  }));

  const BlockedStyledButton = styled(Button)(({ theme }) => ({
    width: '100px',
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px', // Or 2.3
    backgroundColor: 'red'
  }));

  const data = [
    {
      RequestorID: "PQREQ1",
      Customer_Name: "Aman",
      Join_Date: "12/05/25",
      Location: "India",
      Total_Posted: "10",
      Total_Earned: "$ 1,899",
      Last_Post: "20",
      Block_Status: <StyledButton>Active</StyledButton>,
      View: <IconButton onClick={() => setShow(true)} > <Visibility /></IconButton>,
    },
    {
      RequestorID: "PQREQ2",
      Customer_Name: "Pyush",
      Join_Date: "11/06/26",
      Location: "America",
      Total_Posted: "20",
      Total_Earned: "$ 1,800",
      Last_Post: "10",
      Block_Status: <BlockedStyledButton>Blocked</BlockedStyledButton>,
      View: <IconButton onClick={() => setShow(true)} > <Visibility /></IconButton>,
    },

  ];
  const columns = [
    {
      accessorKey: "RequestorID",
      header: "Requestor ID",
      size: 150,
    },
    {
      accessorKey: "Customer_Name",
      header: "Customer Name",
      size: 150,
    },
    {
      accessorKey: "Join_Date",
      header: "Join Date",
      size: 200,
    },
    {
      accessorKey: "Location",
      header: "Location",
      size: 150,
    },
    {
      accessorKey: "Total_Posted",
      header: "Total Posted",
      size: 150,
    },
    {
      accessorKey: "Total_Earned",
      header: "Total Earned",
      size: 200,
    },
    {
      accessorKey: "Last_Post",
      header: "Last Post",
      size: 200,
    },
    {
      accessorKey: "Block_Status",
      header: "Block_Status",
      size: 200,
    },
    {
      accessorKey: "View",
      header: "View",
      size: 100,
    },
  ];

  

  return (
    <div className="p-5 px-8 animate-fade flex gap-8">
      <div className="flex  flex-col w-full gap-3">
        {show ? (<RequesterDetails/>) : (<div className="flex flex-row items-center justify-between">  {/* Example */}
          <p className="text-4xl font-Poppins font-semibold">Requester</p>
          <div>  <Button
            sx={{
              display: 'inline-flex',
              padding: '5px 25px',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '10px',
              borderRadius: '12px',
              background: '#00B2D6',
              color: 'white',
            }}
          >
            <img src={Filter} />
            Filter
          </Button> </div>
        </div>)}
        {!show && (<ReactVirtualizedTable data={data} columnsfrom={columns} />)}
      </div>
    </div>
  );
}

export default Requester;
