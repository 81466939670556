import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { $crud } from "../../utils/CrudFactory";

function EditService({ setedit, edit }) {
  const [service, setService] = useState(null);
  const { catId } = useParams();
  async function handleEdit() {
    try {
      await $crud.put("admin/service", {
        _id: edit._id,
        category: service || edit.category,
        categoryId: catId,
      });
      setedit(false);
    } catch (e) {
      console.error(e);
    }
  }
  return (
    <div className="p-5 px-8 animate-fade flex gap-8">
      <div className="flex flex-col w-full gap-3">
        <div className="flex font-Poppins gap-2 items-center">
          <p
            onClick={() => setedit(false)}
            className="text-4xl cursor-pointer font-semibold"
          >
            &larr;
          </p>
          <p className="text-4xl font-semibold">Edit Service</p>
        </div>
        <div className="bg-stone-100 flex flex-col justify-between font-Poppins border p-10 h-[480px] rounded-2xl">
          <div className="flex flex-col gap-2">
            <label className="text-xl font-semibold">Service Name</label>
            <input
              autoFocus
              value={service}
              onChange={(e) => setService(e.target.value)}
              type="text"
              defaultValue={edit.category}
              className="w-96 rounded-md border outline-none px-4 focus:ring-1 ring-light-text-neww h-10"
            />
          </div>
          <div className="flex gap-3 justify-end items-center">
            <button
              onClick={() => {
                setedit(false);
              }}
              className="bg-red-500 rounded-md text-white p-2 px-4"
            >
              Cancel
            </button>
            <button
              onClick={handleEdit}
              className="bg-light-background-neww rounded-md text-white p-2 px-4"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditService;
